<template>
  <div class="page-container">
    <div class="options-row">
      <Button @click="add" type="primary" icon="md-add">新增产业区</Button>
    </div>
    <Table :loading="tableLoading" :columns="tableColumns" :data="tableData">
    </Table>
    <Modal :width="720" v-model="modalData.show" :title="modalData.title">
      <Form :model="modalData.form" :rules="modalData.rules" :label-width="100">
        <FormItem label="名称" prop="name">
          <Input v-model="modalData.form.name"></Input>
        </FormItem>
        <FormItem label="分组">
          <Select v-model="modalData.form.groupId">
            <Option
              v-for="(item, index) in catList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="地理数据" prop="position">
          <Input
            v-model="modalData.form.position"
            @on-click="openDraw"
            readonly
            icon="md-flag"
          ></Input>
        </FormItem>
        <FormItem label="生产区介绍" prop="content">
          <div style="width: 100%" ref="editor"></div>
        </FormItem>
      </Form>
      <div slot="footer" style="text-align: center">
        <Button :loading="modalData.loading" @click="submit" type="primary">
          提交
        </Button>
        <Button @click="modalData.show = false">取消</Button>
      </div>
    </Modal>
    <Modal fullscreen v-model="drawModalShow" title="绘制生产区">
      <draw-map
        v-if="drawModalShow"
        :mapPosition="drawDefault"
        ref="drawMap"
      ></draw-map>
      <div slot="footer" style="text-align: center">
        <Button @click="submitMap" type="primary">提交</Button>
        <Button @click="drawModalShow = false">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import E from "wangeditor";
import DrawMap from "@/components/drawMap";
import { V1 } from "@/common/http/api";
import { Poptip } from "view-design";

export default {
  components: {
    DrawMap,
  },
  data() {
    return {
      modalData: {
        show: false,
        title: "",
        form: {
          id: "",
          name: "",
          position: "",
          content: "",
          groupId: "",
        },
        rules: {},
        loading: false,
      },
      editor: null,
      drawModalShow: false,
      drawDefault: null,
      tableData: [],
      catList: [],
      tableColumns: [
        {
          title: "名称",
          key: "name",
        },
        {
          title: "分组",
          key: "groupName",
        },
        {
          title: "操作",
          width: 200,
          render: (h, { row }) => {
            return (
              <div>
                <a on-click={() => this.editRow(row)}>编辑</a>
                &nbsp;&nbsp;
                <Poptip
                  on-on-ok={() => this.deleteRow(row)}
                  transfer
                  confirm
                  title="确定删除吗?"
                >
                  <a>删除</a>
                </Poptip>
              </div>
            );
          },
        },
      ],
      tableLoading: false,
    };
  },
  watch: {
    "modalData.show": {
      handler(val) {
        if (val) {
          if (!this.editor) {
            this.editor = new E(this.$refs.editor);
            this.editor.config.uploadImgServer = V1 + this.$api.UPLOAD.FILE;
            this.editor.config.uploadImgMaxLength = 5;
            this.editor.config.uploadImgParams = {
              folder: "image",
              userId: localStorage.userId,
              companyNo: localStorage.companyNo,
            };
            this.editor.config.uploadFileName = "file";
            this.editor.config.uploadImgHeaders = {
              Authorization: "Bearer_" + localStorage.token,
            };
            this.editor.config.uploadImgHooks = {
              customInsert: function (insertImgFn, result) {
                insertImgFn(result.data.filePath);
              },
            };
            this.editor.config.height = 400;
            this.editor.create();
          }
          if (this.modalData.form.content) {
            this.editor.txt.html(this.modalData.form.content);
          }
        } else {
          this.modalData.form = {
            id: "",
            name: "",
            position: "",
            content: "",
            groupId: "",
          };
          this.modalData.loading = false;
          if (this.editor) {
            this.editor.txt.clear();
          }
        }
      },
    },
    drawModalShow(val) {
      if (!val) {
        this.drawDefault = null;
      }
    },
  },
  methods: {
    add() {
      this.modalData.title = "新增产业区信息";
      this.modalData.show = true;
    },
    editRow(row) {
      this.modalData.form.id = row.id;
      this.modalData.form.name = row.name || "";
      this.modalData.form.position = row.position || "";
      this.modalData.form.content = row.content || "";
      this.modalData.form.groupId = row.groupId || "";
      this.modalData.title = "编辑产业区信息";
      this.modalData.show = true;
    },
    deleteRow(row) {
      this.$post(this.$api.REGION_MAP.DELETE, {
        id: row.id,
      }).then((res) => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    openDraw() {
      if (this.modalData.form.position) {
        this.drawDefault = JSON.parse(this.modalData.form.position);
      }
      this.drawModalShow = true;
    },
    submitMap() {
      let mapPosition = this.$refs.drawMap.getCurrentFeatures();
      this.drawDefault = mapPosition;
      this.modalData.form.position = JSON.stringify(mapPosition);
      this.drawModalShow = false;
    },
    submit() {
      this.modalData.loading = true;
      let params = { ...this.modalData.form };
      params.content = this.editor.txt.html();
      this.$post(
        params.id ? this.$api.REGION_MAP.EDIT : this.$api.REGION_MAP.ADD,
        params
      )
        .then((res) => {
          this.$Message.success("操作成功");
          this.modalData.show = false;
          this.getList();
        })
        .finally(() => {
          this.modalData.loading = false;
        });
    },
    getList() {
      this.tableLoading = true;
      this.$post(this.$api.REGION_MAP.LIST, {
        pageNo: 1,
        pageSize: 9999,
      })
        .then((res) => {
          this.tableData = res.list;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getCat() {
      this.$post(this.$api.REGISTER.SCOPE, {
        pid: 499,
      }).then((res) => {
        this.catList = res.list;
      });
    },
  },
  mounted() {
    this.getList();
    this.getCat();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>